import { ssoEnabled } from "./ssoEnabled";
import { getCurrentSelectedTenant, getIsSjAdminFromStorage } from "./user";

export const getHeaders = async () => {
  const svcAuthToken = localStorage.getItem("svcAuthToken");
  const authToken = localStorage.getItem("authToken");
  const idToken = localStorage.getItem("idToken");
  const meta = localStorage.getItem("meta");
  const locale = localStorage.getItem("i18nextLng");
  const useSvcAuth = sessionStorage.getItem("useSvcAuth") === "true";
  const selectedTenant = getCurrentSelectedTenant();
  const isSjAdmin = getIsSjAdminFromStorage();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["Authorization"] = useSvcAuth ? `Bearer ${svcAuthToken}` : `Bearer ${authToken}`;
  }

  if (idToken && !useSvcAuth) {
    headers["IdToken"] = `Bearer ${idToken}`;
  }

  if (meta && !useSvcAuth) {
    headers["Meta"] = `Bearer ${meta}`;
  }

  if (svcAuthToken && !useSvcAuth) {
    headers["SvcAuthorization"] = `Bearer ${svcAuthToken}`;
  }

  if (locale) {
    headers["locale"] = locale;
  }

  if (selectedTenant) {
    headers["SelectedTenant"] = selectedTenant;
  }

  if (isSjAdmin) {
    headers["IsSjAdmin"] = true;
  }

  headers["ssoEnabled"] = ssoEnabled().toString();

  return headers;
};

export const buildHeaderForUpload = async () => {
  const headers = await getHeaders();
  headers["Content-Type"] = "multipart/form-data";
  return headers;
};
