import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import getClient from "../client";
import { INIT_LOGIN_PATH } from "../routes/landing";
import { setUserArtifacts } from "./user";
import { cognitoPoolData } from "../constants/cognitoPoolData";
import humps from "humps";
import { ssoEnabled } from "./ssoEnabled";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

export const getCognitoUserPool = () => {
  return new CognitoUserPool(cognitoPoolData());
};

export const getCognitoJwt = () => {
  return new Promise((resolve, reject) => {
    const user = getCognitoCurrentUser();

    user.getSession(async (err, session) => {
      if (err) return reject(err);

      resolve(session.getIdToken().getJwtToken());
    });
  });
};

export const getCognitoCurrentUser = () => {
  const user = new CognitoUserPool(cognitoPoolData());
  return user.getCurrentUser();
};

export const getCognitoUser = ({ email }) => {
  const userPool = getCognitoUserPool();

  const userData = {
    Username: decodeURIComponent(email.toLowerCase()),
    Pool: userPool,
  };

  return new CognitoUser(userData);
};

export const cognitoLogin = ({ email, password }) => {
  const userData = {
    Username: email.toLowerCase(),
    Password: password,
  };

  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(userData);
  const cognitoUser = getCognitoUser({ email });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async result => {
        const params = {
          access_token: result.accessToken.jwtToken,
          refresh_token: result.refreshToken.token,
          id_token: result.idToken.jwtToken,
          ssoEnabled: ssoEnabled(),
        };
        try {
          const {
            data: { code },
          } = await getClient().post(INIT_LOGIN_PATH, params);
          resolve(code);
        } catch (err) {
          reject(err.message);
        }
      },
      onFailure: err => reject(err.message),
    });
  });
};

export const setLoginToken = tokensData => {
  const tokens = humps.camelizeKeys(tokensData);
  setUserArtifacts(tokens);
};
