import { APP_API_URL } from "../constants/urls";

const API = APP_API_URL;

export const AFTER_SIGN_OUT_PATH = "/logged-out";
export const CONFIRMATION_PATH = "/user/confirmation";
export const FORGOT_PASSWORD_PATH = "/user/password/new";
export const VERIFY_EMAIL_PATH = "/verify/email/:code";
export const HOME_PATH = "/";
export const PASSWORD_EDIT_PATH = "/user/password/edit";
export const SIGN_IN_PATH = "/user/sign_in";
export const SIGN_UP_PATH = "/user/sign_up";
export const SET_UP_PATH = "/user/sign_up?set_up=true";
export const SAML_SIGN_IN_PATH = "/saml/sign_in";
export const SAML_REDIRECT_SIGN_IN_PATH = "/saml/redirect_sign_in";
export const SJ_ADMIN_SIGN_IN_PATH = "/sj-admin/sign_in";

export const HE_LOGIN_PATH = "/he_login";
export const HE_SJ_ADMIN_LOGIN_PATH = "/he_sj_admin_login";

export const SAML_LOGIN_PATH = "/auth";
export const SAML_SJ_ADMIN_LOGIN_PATH = "/sj_admin_auth";

export const VERIFY_USER_PATH = `${API}/verify/user`;

export const INIT_LOGIN_PATH = `${API}/init_he_login`;

export const API_HE_LOGIN_PATH = "/api/he_login";
export const API_SJ_ADMIN_LOGIN_PATH = `${API}/he_sj_admin_login`;

export const API_SAML_LOGIN_PATH = "/api/auth";
export const API_SAML_SJ_ADMIN_LOGIN_PATH = "/api/sj_admin_auth";

export const API_PASSWORD_RESET_PATH = `${API}/user/password`;
export const API_BEGIN_USER_PASSWORD_RESET = `${API}/user/password/reset`;
