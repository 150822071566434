import i18next from "i18next";
import getClient from "../client";
import { toast } from "react-toastify";
import {
  apiCurrentUserPath,
  API_EXPORT_USER_DATA_PATH,
  API_UPDATE_CURRENT_USER_PATH,
  API_SWITCH_TENANT_PATH,
  LEAVE_VD_PATH,
} from "../routes/user";
import { goToAdminHome, goToHome } from "../helpers/pages";
import {
  cleanUserArtifacts,
  getAuthenticated,
  getAuthToken,
  getSvcAuthToken,
  getRefreshToken,
  getIdToken,
  saveAuthToken,
  saveSvcAuthToken,
  saveRefreshToken,
  saveIdToken,
  getEmailFromJWT,
  getDeviceKeyFromJWT,
  retrieveSvcAuthToken,
  getIsImpersonating,
  setIsSjAdminToStorage,
  exchangeJwtTokenToGetSjAdminTenantAccess,
  handleRefreshSvcAuthToken,
  removeCurrentSelectedTenant,
  removeImpersonatedEmail,
  saveSelectedTenant,
} from "../helpers/user";

import { getCognitoCurrentUser, getCognitoJwt, setLoginToken } from "../helpers/cognito";
import { cognitoPoolData } from "../constants/cognitoPoolData";
import { ssoEnabled } from "../helpers/ssoEnabled";

import {
  CognitoUserPool,
  CognitoUser,
  CognitoRefreshToken,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { AFTER_SIGN_OUT_PATH } from "../routes/landing";

import { svcDirectoryClient } from "../helpers/serviceClients/directoryService";

const DEFAULT_LOGIN_ERROR = "Error logging in. Please try again.";

const getRefreshCallback = ({ dispatch, isSjAdmin, state }) => {
  if (getIsImpersonating()) {
    return new Promise(resolve => {
      goToAdminHome();
      resolve();
    });
  }

  const refreshToken = getRefreshToken();
  const idToken = getIdToken();
  const email = getEmailFromJWT(idToken);

  return new Promise(resolve => {
    const sjAdmin = isSjAdmin ? isSjAdmin : state.site.isSjAdmin;
    const userPool = new CognitoUserPool(
      cognitoPoolData({ isSjAdmin: sjAdmin, ssoEnabled: ssoEnabled() })
    );

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

    user.refreshSession(cognitoRefreshToken, async (err, session) => {
      if (err) {
        console.error("error with refresh session: ", err);
        dispatch.user.signOut();
      } else {
        saveAuthToken(session.accessToken.jwtToken);
        saveRefreshToken(session.refreshToken.token);
        saveIdToken(session.idToken.jwtToken);
        const svcToken = await handleRefreshSvcAuthToken(session.idToken.jwtToken, { isSjAdmin });
        saveSvcAuthToken(svcToken);
        await dispatch.user.setLoggedIn();
        resolve();
      }
    });
  });
};

export default {
  state: {
    // auth
    isAuthenticated: getAuthenticated(),
    authToken: getAuthToken(),
    refreshToken: getRefreshToken(),
    svcAuthToken: getSvcAuthToken(),

    isLoaded: false,

    duo: null,

    // attrs
    id: null,
    email: "",
    name: "",
    firstName: "",
    lastName: "",
    locale: null,
    title: "",
    company: "",
    jobRole: "",
    country: "",
    statusEmail: false,
    consent: false,
    currentRank: null,
    trial: true,
    role: "",
    certFirstName: "",
    certLastName: "",

    // image
    gravatarId: "",
    smallImageUrl: "",
    thumbImageUrl: "",

    // map
    journeymapScaleIndex: 1,
    journeymapType: "map",
    listViewMode: null,
    openedGuides: [],

    //interface
    interfaceType: "map",

    // stats
    rating: 0,
    totalPoints: 0,

    // module
    moduleViewMode: "video",

    latestChangesLastDateViewed: "",

    // boolean
    canSelectRoles: false,
    hasAccessToDojoActivities: false,
    hasCurrentCourse: false,
    isAssessmentMessageShown: false,
    isDojoPreviewShown: false,
    isParticipantOfLeaderboard: false,
    isParticipantOfAchievement_wall: false,
    newFeatureTourCompleted: false,
    breakFixFeatureTourCompleted: false,
    signupSurveyCompleted: true,
    showAdminTour: false,
    showJourneyTour: false,
    showJourneyIntroVideo: false,
    showLearnTour: false,
    showBreakFixTour: false,

    // SJ Admin Roles
    hasReadOnly: false,
    hasReadWriteOnly: false,
    hasCsmOnly: false,
    hasContentOnly: false,
    hasSuperOnly: false,
    hasCloneAccess: true,

    // visible options
    canSeeWhiteButton: false,
    canSeeWhiteShadow: false,
    canSeeYellowButton: false,
    canSeeYellowShadow: false,
    canSeeGreenButton: false,
    canSeeGreenShadow: false,

    // current level
    currentLevel: {
      activeRolesNumber: null,
      id: null,
      name: "",
      rankName: "",
      statsProgress: 0,
      statsCurrent: 0,
      statsTotal: 0,
      userRole: null,
    },

    // passed roles
    passedBelts: [],

    // last passed enrollment
    passedEnrollment: {
      id: null,
      completedAtDate: null,
      completionShowModal: false,
      levelId: null,
      levelName: "",
      levelRankName: "",
      levelDescription: "",
      levelHasDojoActivities: false,
      role: null,
    },
    videoSettings: {},
    isUpdating: false,
    recordsPerPage: null,
    loginErrorMessage: "",
    selectedEntity: {},
    isScorm: false, // NOTE: we should probably change this variable name when we implement 1st Party Scorm
  },

  reducers: {
    set: (state, payload) => ({ ...state, ...payload }),

    setSignupSurveyCompleted: (state, signupSurveyCompleted) => ({
      ...state,
      signupSurveyCompleted,
    }),

    update: (state, payload) => ({ ...state, isUpdating: false, ...payload }),
    multipleUpdate: (state, payload) => ({ ...state, ...payload }),
    // used in userProfile
    setName: (state, name) => ({ ...state, name }),

    updateImage: (state, { smallImageUrl, thumbImageUrl }) => ({
      ...state,
      smallImageUrl,
      thumbImageUrl,
    }),

    makeAssessmentMessageShown: state => ({
      ...state,
      isAssessmentMessageShown: true,
    }),

    scormReset: (state, payload) => payload,
  },

  effects: dispatch => ({
    async trackTime({ component, duration, params, timePath }) {
      const users = {
        component,
        duration,
        parameters: params,
      };
      await getClient().post(timePath, { users });
    },

    async exportUser() {
      const { data } = await getClient().get(API_EXPORT_USER_DATA_PATH);
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
      const elem = document.getElementById("downloadAnchorElem");
      elem.setAttribute("href", dataStr);
      elem.setAttribute("download", "user.json");
      elem.click();
    },

    async loadCurrent() {
      const {
        data: {
          currentLevel,
          lastPassedEnrollment: passedEnrollment,
          passedBelts,
          user,
          visibleOptions,
        },
      } = await getClient().get(
        apiCurrentUserPath([
          "current_level",
          "last_passed_enrollment",
          "passed_belts",
          "user",
          "visible_options",
        ])
      );

      if (currentLevel) {
        dispatch.user.update({ currentLevel });
        dispatch.user__currentCourse.updateStats(currentLevel);
      }

      if (passedEnrollment) {
        dispatch.user.update({ passedEnrollment });
      }

      if (passedBelts) {
        const updatedPassedBelts = passedBelts.map(pb => ({ ...pb, isContextOpen: false }));
        dispatch.user.update({ passedBelts: updatedPassedBelts });
      }

      if (user) {
        dispatch.user.update({ ...user });
      }

      if (visibleOptions) {
        dispatch.user.update({ ...visibleOptions });
      }

      dispatch.user.update({ isLoaded: true });
    },

    async updateModuleViewMode(moduleViewMode) {
      const userParams = { user: { moduleViewMode } };
      dispatch.user.update({ moduleViewMode });
      const { data } = await getClient().put(API_UPDATE_CURRENT_USER_PATH, userParams);

      const { user } = data || {};
      if (user) {
        dispatch.user.update({
          moduleViewMode: user.moduleViewMode || "video",
        });
      }
    },

    async loadAdmin() {
      const {
        data: { user },
      } = await getClient().get(apiCurrentUserPath(["admin"]));

      if (user) {
        dispatch.user.update({ ...user });
        dispatch.user.update({ hasReadOnly: user.role === "sj_admin_read_only" });
        dispatch.user.update({ hasReadWriteOnly: user.role === "sj_admin_read_write" });
        dispatch.user.update({ hasCsmOnly: user.role === "sj_admin_csm" });
        dispatch.user.update({ hasContentOnly: user.role === "sj_admin_content" });
        dispatch.user.update({ hasSuperOnly: user.role === "sj_super_admin" });
      }

      dispatch.user.update({ isLoaded: true });
    },

    // always pass an object {} otherwise you will receive 500
    async cognitoRefresh(options, state) {
      const isSjAdmin = options?.isSJAdmin;
      try {
        const refreshCallback = await getRefreshCallback({ dispatch, isSjAdmin, state });

        return refreshCallback;
      } catch (error) {
        console.log("ERROR", error);
        dispatch.user.signOut();
      }
    },

    async loginAsync({
      path,
      code,
      redirect = true,
      redirectPath = null,
      isSjAdmin = false,
      tenantId = null,
    }) {
      try {
        dispatch.user.set({ loginErrorMessage: "" });
        const { data: tokensData } = await getClient().post(path, {
          code,
          sj_admin: isSjAdmin,
          tenantId,
          local: document.location.hostname === "localhost",
        });

        setLoginToken(tokensData);

        const cognitoUserSession = new CognitoUserSession({
          IdToken: new CognitoIdToken({ IdToken: tokensData.idToken }),
          AccessToken: new CognitoAccessToken({ AccessToken: tokensData.accessToken }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken: tokensData.refreshToken }),
        });

        const email = getEmailFromJWT(tokensData.idToken);
        const deviceKey = getDeviceKeyFromJWT(tokensData.accessToken);

        // Need to set isSjAdmin to storage before retrieving auth token
        if (isSjAdmin) {
          setIsSjAdminToStorage();
        }
        await retrieveSvcAuthToken(tokensData.idToken);

        const userPool = new CognitoUserPool(
          cognitoPoolData({
            isSjAdmin,
          })
        );

        const cognitoUser = new CognitoUser({
          Username: email,
          Pool: userPool,
        });

        cognitoUser.setSignInUserSession(cognitoUserSession);

        if (deviceKey) {
          cognitoUser.cacheTokens();
          cognitoUser.cacheDeviceKeyAndPassword();

          // eslint-disable-next-line prettier/prettier

          // prettier-ignore
          const keyPrefix = `CognitoIdentityServiceProvider.${cognitoUser.pool.getClientId()}.${cognitoUser.username}`;

          localStorage.setItem(`${keyPrefix}.deviceKey`, deviceKey);

          cognitoUser.getCachedDeviceKeyAndPassword();
        }
        if (redirect) {
          window.location.href = redirectPath || "/";
        }
      } catch (error) {
        dispatch.user.set({ loginErrorMessage: DEFAULT_LOGIN_ERROR });
        throw error;
      }
    },

    signOut() {
      const cognitoUser = getCognitoCurrentUser();
      if (dispatch.user__courses) {
        dispatch.user__courses.clearData(true);
      }

      if (cognitoUser) {
        cognitoUser.signOut();
      }
      cleanUserArtifacts();
      dispatch.user.update({
        authToken: null,
        isAuthenticated: false,
        refreshToken: null,
        id: null,
      });
      window.location.href = AFTER_SIGN_OUT_PATH;
    },

    async stopImpersonating(tenantName) {
      /** get back tenant access to go to admin home with a correct tenant access after the stopImpersonating */
      const jwtToken = await getCognitoJwt();
      await exchangeJwtTokenToGetSjAdminTenantAccess({ tenantName, jwtToken });
      removeImpersonatedEmail();
      goToAdminHome();
    },

    async switchTenantMeta({ tenantId }) {
      const {
        data: { metaToken },
      } = await getClient().put(API_SWITCH_TENANT_PATH, { tenantId });
      localStorage.setItem("meta", metaToken);

      goToHome();
    },

    async switchTenantSvcAuth({ tenantName }) {
      saveSelectedTenant(tenantName);

      goToHome();
    },

    async leaveVd() {
      //* get a new svc auth token without an impersonating role or a tenant access */
      const jwtToken = await getCognitoJwt();
      const isSjAdmin = localStorage.getItem("isSJAdmin");
      removeCurrentSelectedTenant();
      removeImpersonatedEmail();
      //* get a new svc auth token without an impersonating role or a tenant access */
      await handleRefreshSvcAuthToken(jwtToken, { isSjAdmin });

      const {
        data: { meta },
      } = await getClient().get(LEAVE_VD_PATH);

      localStorage.setItem("meta", meta);

      goToAdminHome();
    },

    async multipleUpdateAttribute(body) {
      const { params, useToast = true, shouldMerge = false } = body;
      const { data } = await getClient({ useToast }).put(API_UPDATE_CURRENT_USER_PATH, {
        user: params,
        merge: shouldMerge,
      });
      const { user } = data || {};

      dispatch.user.multipleUpdate(user);
    },

    async updateAttribute({ name, value, cb, shouldUpdate = true }) {
      try {
        dispatch.user.update({ isUpdating: true });
        let params = { user: { [name]: value } };
        const { data } = await getClient().put(API_UPDATE_CURRENT_USER_PATH, params);
        const { user } = data || {};

        if (shouldUpdate) {
          dispatch.user.update({ [name]: user[name] });
        }

        if (cb) {
          cb(""); // remove error message on success
        }
      } catch (err) {
        const { response } = err;

        let errorMessage = i18next.t("user.errors.somethingWentWrong");

        if (response && response.data && response.data.errors) {
          const { errors } = response.data;

          if (Array.isArray(errors)) {
            errorMessage = errors[0];
          } else if (typeof errors === "object" && errors !== null) {
            errorMessage = Object.entries(errors).join(", ");
          } else {
            errorMessage = errors;
          }
        }

        if (cb) {
          cb(errorMessage);
        }
      }
    },

    async updateUser({ email, firstName, lastName, jobRole, title, country, company }) {
      try {
        const { data: userProfile, error } = await svcDirectoryClient.updateSelf(
          email,
          firstName,
          lastName,
          {
            jobRole: jobRole,
            title: title,
            country: country,
            company: company,
          }
        );
        if (error) {
          throw error;
        }
        const user =
          {
            email: userProfile.email,
            firstName: userProfile.name.givenName,
            lastName: userProfile.name.familyName,
            jobRole: userProfile.attributes.jobRole,
            title: userProfile.attributes.title,
            company: userProfile.attributes.company,
            country: userProfile.attributes.country,
          } || {};
        dispatch.user.multipleUpdate(user);
      } catch (e) {
        toast.error(e.response.data.message);
      }
    },

    async toggleAdminTour(showAdminTour) {
      dispatch.user.update({ showAdminTour });
    },

    async toggleJourneyTour(showJourneyTour) {
      dispatch.user.update({ showJourneyTour });
    },

    async toggleLearnTour(showLearnTour) {
      dispatch.user.update({ showLearnTour });
    },

    async toggleBreakFixTour(showBreakFixTour) {
      dispatch.user.update({ showBreakFixTour });
    },

    async toggleJourneyIntroVideo(showJourneyIntroVideo) {
      dispatch.user.update({ showJourneyIntroVideo });
    },

    openAchievementContext(id, state) {
      const { passedBelts } = state.user;
      const updatedPassedBelts = passedBelts.map(pb => {
        if (pb.id === id) {
          return { ...pb, isContextOpen: !pb.isContextOpen };
        }
        return { ...pb, isContextOpen: false };
      });
      dispatch.user.update({ passedBelts: updatedPassedBelts });
    },
    outsideAchievementContext(id, state) {
      const { passedBelts } = state.user;
      const updatedPassedBelts = passedBelts.map(pb => {
        if (pb.id === id) {
          return { ...pb, isContextOpen: false };
        }
        return pb;
      });
      dispatch.user.update({ passedBelts: updatedPassedBelts });
    },
    setLoggedIn() {
      dispatch.user.set({ isAuthenticated: true });
    },

    updateListViewMode(listViewMode) {
      dispatch.user.set({ listViewMode });
    },

    setLoginErrorMessage(errorMessage = DEFAULT_LOGIN_ERROR) {
      dispatch.user.set({ errorMessage });
    },
    updateSelectedEntity(selectedEntity) {
      dispatch.user.set({ selectedEntity });
    },

    addOpenedGuides(type, state) {
      const { openedGuides } = state.user;
      const updatedOpenedGuides = [...openedGuides, type];
      dispatch.user.set({ openedGuides: updatedOpenedGuides });
    },

    removeOpenedGuides(type, state) {
      const { openedGuides } = state.user;
      const updatedOpenedGuides = openedGuides.filter(g => g !== type);
      dispatch.user.set({ openedGuides: updatedOpenedGuides });
    },
  }),
};
