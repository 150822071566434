export const cognitoPoolData = () => {
  const userPoolId = getCorrectPoolId();
  const clientId = getCorrectClientId();

  return {
    UserPoolId: userPoolId,
    ClientId: clientId,
  };
};

const getCorrectPoolId = () => {
  return process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || window.awsCognitoUserPoolId;
};

const getCorrectClientId = () => {
  return process.env.REACT_APP_AWS_COGNITO_CLIENT_ID || window.awsCognitoClientId;
};
